//auth module
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const CHECK_AUTH = "checkAuth";
export const FETCH_USER_PERMISSIONS = "fetchUserPermissions";
export const IMPERSONATE_USER = "IMPERSONATE_USER";
export const REVERT_TO_USER = "revertToUser";


//config module
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
export const RESET_LAYOUT_CONFIG = "resetLayoutConfig";
export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig";

//htmlclass module
export const ADD_BODY_CLASSNAME = "addBodyClassName";
export const REMOVE_BODY_CLASSNAME = "removeBodyClassName";
export const ADD_CLASSNAME = "addClassName";

//breadcrumb module
export const APPEND_BREADCRUM = "appendBreadcrumb";

//frontend
//services
export const FETCH_F_SERVICES = "fetchFServices";
export const FETCH_SCHEDULE_TIMES = "fetchScheduleTimes";
export const FETCH_SCHEDULE_TIMES_FOR_BACKEND =
  "FETCH_SCHEDULE_TIMES_FOR_BACKEND";
//clients
export const FETCH_F_CLIENTS = "fetchFClients";
export const FETCH_F_CLIENT_RATE = "fetchClientRate";

//Abbreviation
export const CHECK_F_ABBREVIATION_CODE = "checkFAbbreviationCode";
export const CHECK_F_ARCHIVAL_ABBREVIATION_CODE =
  "CHECK_F_ARCHIVAL_ABBREVIATION_CODE";

//appointment
export const STORE_C_APPOINTMENT_BASE_PLAN = "storeCAppoinmentBasePlan";
export const FETCH_C_APPOINTMENT_BASE_PLAN = "fetchCAppoinmentBasePlan";
export const STORE_APPOINTMENT_NEW_BASE_PLAN =
  "STORE_APPOINTMENT_NEW_BASE_PLAN";
export const FETCH_NEW_BASE_PLAN = "FETCH_NEW_BASE_PLAN";
export const STORE_APPOINTMENT = "STORE_APPOINTMENT";
export const STORE_APPOINTMENT_ID = "STORE_APPOINTMENT_ID";
export const APPOINTMENT_REFUND = "APPOINTMENT_REFUND";
export const STORE_APPOINTMENT_APPLICANT_INFO =
  "STORE_APPOINTMENT_APPLICANT_INFO";
export const STORE_APPOINTMENT_APPLICANT_INFO_ID =
  "STORE_APPOINTMENT_APPLICANT_INFO_ID";
export const STORE_APPLICANT_PERSONAL_INFO = "STORE_APPLICANT_PERSONAL_INFO";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const VERIFY_OTP_SMS = "VERIFY_OTP_SMS";
export const VERIFY_OTP_EMAIL = "VERIFY_OTP_EMAIL";
export const RESEND_OTP_SMS = "RESEND_OTP_SMS";
export const RESEND_OTP_EMAIL = "RESEND_OTP_EMAIL";
//schedules
export const STORE_SCHEDULE_HOLIDAY = "storeScheduleHoliday";
export const FETCH_SCHEDULES = "fetchSchedules";
export const DELETE_SCHEDULES = "deleteSchedules";
export const STORE_SCHEDULE_BASEPLAN = "storeScheduleBasePlan";
export const FETCH_SCHEDULE_BASE_PLAN = "fetchScheduleBasePlan";
export const UPDATE_SCHEDULE_STATUS = "UPDATE_SCHEDULE_STATUS";

//master
export const FETCH_CITIES = "fetchCities";
export const FETCH_STATES = "fetchStates";
export const FETCH_COUNTRIES = "fetchCountries";
export const FETCH_RACES = "fetchRaces";
export const FETCH_EYECOLORS = "fetchEyeColors";
export const FETCH_HAIRCOLORS = "fetchHairColors";
export const FETCH_PlACE_OF_BIRTH = "fetchPlaceOfBirth";

//appointment send
export const STORE_APPOINTMENT_FROM_APPLICANT =
  "STORE_APPOINTMENT_FROM_APPLICANT";

export const FETCH_CLIENT_APPOINTMENT = "FETCH_CLIENT_APPOINTMENT";

export const FETCH_CLIENT_ARCHIVAL_APPOINTMENT =
  "FETCH_CLIENT_ARCHIVAL_APPOINTMENT";

export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const FETCH_SERVICES = "FETCH_SERVICES";
export const FETCH_SERVICES_CLIENTS = "FETCH_SERVICES_CLIENTS";
export const FETCH_SERVICE_CODES = "FETCH_SERVICE_CODES";
export const FETCH_SELF_APPOINMENT = "FETCH_SELF_APPOINMENT";
export const UPDATE_APPOINTMENT_STATUS = "UPDATE_APPOINTMENT_STATUS";
export const UPDATE_APPOINTMENT_PAYMENT_STATUS =
  "UPDATE_APPOINTMENT_PAYMENT_STATUS";

//invoice

export const FETCH_INVOICE_DETAILS = "FETCH_INVOICE_DETAILS";
export const FETCH_ENROLLMENT_INVOICE_DETAILS =
  "FETCH_ENROLLMENT_INVOICE_DETAILS";

//payment
export const FETCH_PAYMENT_SETTING = "FETCH_PAYMENT_SETTING";
export const UPDATE_PAYMENT_SETTING = "UPDATE_PAYMENT_SETTING";
export const CARD_COUNTRY = "CARD_COUNTRY";
export const CARD_STATE = "CARD_STATE";
// FETCH_NOTES,
// UPDATE_NOTES,

export const FETCH_NOTES = "FETCH_NOTES";
export const UPDATE_NOTES = "UPDATE_NOTES";

//company
export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const STORE_COMPANY = "STORE_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const GET_COMPANY_DATA = "GET_COMPANY_DATA";

//service
export const FETCH_B_SERVICES = "FETCH_B_SERVICES";
export const STORE_B_SERVICE = "STORE_B_SERVICE";
export const UPDATE_B_SERVICE = "UPDATE_B_SERVICE";
export const DELETE_B_SERVICE = "DELETE_B_SERVICE";
export const GET_B_SERVICE_DATA = "GET_B_SERVICE_DATA";

//user

export const FETCH_B_USER = "FETCH_B_USER";
export const STORE_B_USER = "STORE_B_USER";
export const UPDATE_B_USER = "UPDATE_B_USER";
export const DELETE_B_USER = "DELETE_B_USER";

export const UPDATE_USER_DETAIL = "UPDATE_USER_DETAIL";

//location
export const FETCH_B_LOCATIONS = "FETCH_B_LOCATIONS";
export const STORE_B_LOCATION = "STORE_B_LOCATION";
export const UPDATE_B_LOCATION = "UPDATE_B_LOCATION";
export const DELETE_B_LOCATION = "DELETE_B_LOCATION";
export const GET_B_LOCATION_DATA = "GET_B_LOCATION_DATA";
export const GET_B_USER_DATA = "GET_B_USER_DATA";
export const FETCH_B_LOCATION_RATE_LISTS = "FETCH_B_LOCATIONRATELISTS";
export const UPDATE_B_LOCATION_RATE_LISTS = "UPDATE_B_LOCATION_RATE_LISTS";
export const FETCH_B_LOCATION_USER = "FETCH_B_LOCATION_USER";

//profile

export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";

//setting
export const FETCH_F_NOTE = "FETCH_F_NOTE";
export const UPDATE_PROFILE_PASSWORD = "UPDATE_PROFILE_PASSWORD";
export const EMAIL_PASSWORD_B_USER = "EMAIL_PASSWORD_B_USER";
export const FETCH_SMTP_OUTGOING_EMAIL_CONFIGURE =
  "FETCH_SMTP_OUTGOING_EMAIL_CONFIGURE";
export const STORE_SMTP_OUTGOING_EMAIL_CONFIGURE =
  "STORE_SMTP_OUTGOING_EMAIL_CONFIGURE";
export const CHECK_SMTP_CONNECTION = "CHECK_SMTP_CONNECTION";

export const FETCH_SMS_CONFIG = "FETCH_SMS_CONFIG";
export const SUBMIT_SMS_CONFIG = "SUBMIT_SMS_CONFIG";

export const FETCH_MAP_CONFIG = "FETCH_MAP_CONFIG";
export const SUBMIT_MAP_CONFIG = "SUBMIT_MAP_CONFIG";

//sp
export const FETCH_SERVICE_PROVIDER_DATA = "FETCH_SERVICE_PROVIDER_DATA";

//auth
export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";

//after payment success
export const MAKE_INVOICE_AFTER_ONLINE_PAYEMENT =
  "MAKE_INVOICE_AFTER_ONLINE_PAYEMENT";

// LOG
export const FETCH_PAYMENT_LOG = "FETCH_PAYMENT_LOG";
// reschedule
export const STORE_RESCHEDULE_TIMES = "STORE_RESCHEDULE_TIMES";

// service provider

export const GET_SERVICE_PROVIDER_LIST = "GET_SERVICE_PROVIDER_LIST";
export const CREATE_SERVICE_PROVIDER = "CREATE_SERVICE_PROVIDER";

export const STORE_ADMIN_USER = "STORE_ADMIN_USER";
export const STORE_HOLIDAY = "STORE_HOLIDAY";
export const FETCH_HOLIDAYS = "FETCH_HOLIDAYS";
export const REMOVE_HOLIDAYS = "REMOVE_HOLIDAYS";
export const CHECK_HOLIDAY = "CHECK_HOLIDAY";

// additional service
export const STORE_ADDITIONAL_SERVICE = "STORE_ADDITIONAL_SERVICE";
// archive appointment
export const STORE_APPOINTMENT_ARCHIVE = "STORE_APPOINTMENT_ARCHIVE";

//additional new service
export const LOCATION_SERVICE = "LOCATION_SERVICE";
export const NEW_APPOINTMENT = "NEW_APPOINTMENT";

// DASHBOARD
export const GET_DASHBOARD_CARD_DATA = "GET_DASHBOARD_CARD_DATA";
export const FETCH_DATA_FOR_GRAPH = "FETCH_DATA_FOR_GRAPH";

// report
export const FETCH_COMPANY_WISE_REPORT = "FETCH_COMPANY_WISE_REPORT";
export const FETCH_LOCATION_WISE_REPORT = "FETCH_LOCATION_WISE_REPORT";
export const FETCH_LOCATION_WISE_RECEIVABLE_REPORT =
  "FETCH_LOCATION_WISE_RECEIVABLE_REPORT";
export const FETCH_LOCATION_JOB_WISE_REPORT = "FETCH_LOCATION_JOB_WISE_REPORT";

export const FETCH_LOCATION_NEW_BASE_PLAN = "FETCH_LOCATION_NEW_BASE_PLAN";
export const FETCH_LOCATION_HOLIDAYS = "FETCH_LOCATION_HOLIDAYS";
export const FETCH_SP_ORI_WISE_REPORT = "FETCH_SP_ORI_WISE_REPORT";
export const FETCH_APPOITMENT_DATA_FOR_GRAPH =
  "FETCH_APPOITMENT_DATA_FOR_GRAPH";
export const FETCH_APPOITMENT_LOCATION_DATA_FOR_GRAPH =
  "FETCH_APPOITMENT_LOCATION_DATA_FOR_GRAPH";

export const EXPORT_DATA = "EXPORT_DATA";

export const ADD_USER_DETAIL = "ADD_USER_DETAIL";

export const FETCH_EMAIL_TEMPLETE = "FETCH_EMAIL_TEMPLETE";
export const UPDATE_EMAIL_TEMPLETE = "UPDATE_EMAIL_TEMPLETE";
export const APPOINTMENT_LOGS = "APPOINTMENT_LOGS";
export const REFRESH = "REFRESH";
export const STORE_DRUG_APPOINTMENT_APPLICANT_INFO =
  "STORE_DRUG_APPOINTMENT_APPLICANT_INFO";
export const SUCCESS_DRUG_TESTING_PAYEMENT = "SUCCESS_DRUG_TESTING_PAYEMENT";
export const FETCH_GROUP = "FETCH_GROUP";
export const CREATE_GROUP = "CREATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const CREATE_GROUP_SERVICE = "CREATE_GROUP_SERVICE";
export const GET_GROUP = "GET_GROUP";
export const FEATURED = "FEATURED";
export const FEATUREDUPDATE = "FEATUREDUPDATE";
export const FRONTEND_GET_GROUP = "FRONTEND_GET_GROUP";
export const FRONTEND_SERVICE_SEARCH = "FRONTEND_SERVICE_SEARCH";
export const LOCATIONS = "LOCATIONS";
export const NEWLOCATIONSTORE = "NEWLOCATIONSTORE";

// invoice_master
export const GENETRATE_INVOICE_MASTER='GENETRATE_INVOICE_MASTER';
export const FETCH_INVOICE_MASTER_LIST='FETCH_INVOICE_MASTER_LIST';
export const FETCH_INVOICE_MASTER_BY_ID='FETCH_INVOICE_MASTER_BY_ID';
export const FETCH_INVOICE_MASTER_DETAIL='FETCH_INVOICE_MASTER_DETAIL';
export const DELETE_INVOICE_MASTER='DELETE_INVOICE_MASTER';


//heartland payment setting
export const FETCH_HEART_PAYMENT_SETTING = "FETCH_HEART_PAYMENT_SETTING";
export const UPDATE_HEART_PAYMENT_SETTING = "UPDATE_HEART_PAYMENT_SETTING";
export const RECAPTCHATOKENVERIFY = "RECAPTCHATOKENVERIFY";

//enrollments
export const FETCH_CLIENT_ENROLLMENT = "FETCH_CLIENT_ENROLLMENT";
export const FETCH_ENROLLMENT = "FETCH_ENROLLMENT";
export const FETCH_SELF_ENROLLMENT = "FETCH_SELF_ENROLLMENT";
export const ENROLLMENT_LOGS = "ENROLLMENT_LOGS";
export const TRANSMITSTATUSCHANGE = "TRANSMITSTATUSCHANGE";
//api key setting
export const FETCH_APIKEY = "FETCH_APIKEY";
export const CREATE_APIKEY = "CREATE_APIKEY";
export const GENERATE_APIKEY = "GENERATE_APIKEY";
export const GET_APIKEY = "GET_APIKEY";
export const DELETE_APIKEY = "DELETE_APIKEY";
export const RELOAD_APPOINTMENT = "RELOAD_APPOINTMENT";
export const STORE_TIMER_SETUP = "STORE_TIMER_SETUP";
export const GET_TIMER_SETUP = "GET_TIMER_SETUP";
export const UPDATE_TIMER_SETUP = "UPDATE_TIMER_SETUP";

//roles
export const FETCH_ROLES = "FETCH_ROLES";
export const GET_ROLE = "GET_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const FETCH_ROLE_PERMISSIONS = "FETCH_ROLE_PERMISSIONS";
export const CREATE_ROLE_PERMISSIONS = "CREATE_ROLE_PERMISSIONS";
export const DELETE_ROLE = "DELETE_ROLE";

//archival
export const FETCH_ARCHIVAL = "FETCH_ARCHIVAL";
export const FETCH_ARCHIVALS = "FETCH_ARCHIVALS";
export const GET_ARCHIVAL = "GET_ARCHIVAL";
export const CREATE_ARCHIVAL = "CREATE_ARCHIVAL";
export const DELETE_ARCHIVAL = "DELETE_ARCHIVAL";

export const FETCH_B_ARCHIVAL = "FETCH_B_ARCHIVAL";
export const FETCH_B_ARCHIVAL_RATE_LISTS = "FETCH_B_ARCHIVAL_RATE_LISTS";
export const UPDATE_B_ARCHIVAL_RATE_LISTS = "UPDATE_B_ARCHIVAL_RATE_LISTS";

export const FETCH_F_CLIENT_ARCHIVAL_RATES = "FETCH_F_CLIENT_ARCHIVAL_RATES";

// client jobs

export const FETCH_CLIENT_JOB_LIST = "FETCH_CLIENT_JOB_LIST";
export const FETCH_CLIENT_JOB_DETAILS = "FETCH_CLIENT_JOB_DETAILS";
export const UPDATE_CLINET_JOB_DETAILS = "UPDATE_CLINET_JOB_DETAILS=T";
export const SAVE_CLIENT_JOB_DETAILS = "SAVE_CLIENT_JOB_DETAILS";
export const SEND_CLIENT_JOB_MAIL = "SEND_CLIENT_JOB_MAIL";

//mobile location

export const FETCH_MOBILE_LOCATION_JOB = "FETCH_MOBILE_LOCATION_JOB";
export const STORE_MOBILE_LOCATION_APPOITMENT =
  "STORE_MOBILE_LOCATION_APPOITMENT";

export const FETCH_PDF_TCN = "FETCH_PDF_TCN";
export const FETCH_SERVICEORI = "FETCH_SERVICEORI";
export const FETCH_FLORIDA_SERVICEORI = "FETCH_FLORIDA_SERVICEORI";

export const GET_SERVICEORI = "GET_SERVICEORI";
export const CREATE_SERVICEORI = "CREATE_SERVICEORI";
export const DELETE_SERVICEORI = "DELETE_SERVICEORI";

export const FETCH_ORI_LIST = "FETCH_ORI_LIST";

export const FETCH_DEPORDER_CONFIG = "FETCH_DEPORDER_CONFIG";
export const SUBMIT_DEPORDER_CONFIG = "SUBMIT_DEPORDER_CONFIG";

export const FETCH_FDLE_CONFIG = "FETCH_FDLE_CONFIG";
export const SUBMIT_FDLE_CONFIG = "SUBMIT_FDLE_CONFIG";
export const FETCH_ACHA_CONFIG = "FETCH_ACHA_CONFIG";
export const SUBMIT_ACHA_CONFIG = "SUBMIT_ACHA_CONFIG";

//ori check
export const CHECK_F_SERVICE_ORI_CODE = "CHECK_F_SERVICE_ORI_CODE";

export const GET_API_KEY = "GET_API_KEY";
export const CREATE_API_KEY_DEVICES = "CREATE_API_KEY_DEVICES";
export const DELETE_APIKEY_DEVICE = "DELETE_APIKEY_DEVICE";
export const FETCH_APIKEY_VERSION = "FETCH_APIKEY_VERSION";
export const UPDATE_APIKEY_VERSION = "UPDATE_APIKEY_VERSION";
export const UNSCHEDULE_APIKEY_VERSION = "UNSCHEDULE_APIKEY_VERSION";
export const UPDATE_APIKEY_VERSION_LISTS = "UPDATE_APIKEY_VERSION_LISTS";

export const GET_WHITELIST_EMAIL_LIST = "GET_WHITELIST_EMAIL_LIST";
export const DELETE_WHITELIST_EMAIL = "DELETE_WHITELIST_EMAIL";

export const CREATE_WHITELIST_EMAIL = "CREATE_WHITELIST_EMAIL";
export const FETCH_PROMOCODES = "FETCH_PROMOCODES";
export const DELETE_PROMOCODE = "DELETE_PROMOCODE";

export const UPDATE_PROMOCODE = "UPDATE_PROMOCODE";
export const GET_PROMOCODE_DATA = "GET_PROMOCODE_DATA";
export const STORE_PROMOCODE = "STORE_PROMOCODE";

export const CHECK_F_PROMO_CODE = "CHECK_F_PROMO_CODE";
export const FETCH_WHITELISTEMAIL_DETAILS = "FETCH_WHITELISTEMAIL_DETAILS";

export const FETCH_SERVICECODES = "FETCH_SERVICECODES";

export const CREATE_SERVICECODES = "CREATE_SERVICECODES";

export const FETCH_CLIENT_APIKEYS = "FETCH_CLIENT_APIKEYS";
export const FETCH_APIKEY_LOGS = "FETCH_APIKEY_LOGS";

export const FETCH_EXPIRY_ARCHIVAL_APPOINTMENT =
  "FETCH_EXPIRY_ARCHIVAL_APPOINTMENT";

export const FETCH_ARCHIVAL_ENROLLMENT = "FETCH_ARCHIVAL_ENROLLMENT";
export const ASSIGN_ARCHIVAL_ENROLLMENT = "ASSIGN_ARCHIVAL_ENROLLMENT";

export const CHANGE_ARCHIVAL_DATE = "CHANGE_ARCHIVAL_DATE";
export const ASSIGN_ENROLLMENT_COMPANY = "ASSIGN_ENROLLMENT_COMPANY";

export const DOWNLOAD_CLIENT_ENROLLMENT = "DOWNLOAD_CLIENT_ENROLLMENT";
export const FETCH_CLIENT_JOB_APPOINTMENTLIST =
  "FETCH_CLIENT_JOB_APPOINTMENTLIST";

export const FETCH_SP_ORI_RATE_LISTS = "FETCH_SP_ORI_RATE_LISTS";
export const FETCH_LOCATION_ORI_RATE_LISTS = "FETCH_LOCATION_ORI_RATE_LISTS";

export const UPDATE_SP_ORI_RATE_LISTS = "UPDATE_SP_ORI_RATE_LISTS";
export const UPDATE_LOCATION_ORI_RATE_LISTS = "UPDATE_LOCATION_ORI_RATE_LISTS";

export const FETCH_ADMIN_ENROLLMENT = "FETCH_ADMIN_ENROLLMENT";
export const DOWNLOAD_ENROLLMENT_FILE = "DOWNLOAD_ENROLLMENT_FILE";

export const FETCH_LOCATION_SERVICE_RATE_LISTS =
  "FETCH_LOCATION_SERVICE_RATE_LISTS";

export const UPDATE_LOCATION_SERVICE_RATE_LISTS =
  "UPDATE_LOCATION_SERVICE_RATE_LISTS";
export const STORE_MASTER_COMPANY = "STORE_MASTER_COMPANY";
export const UPDATE_MASTER_COMPANY = "UPDATE_MASTER_COMPANY";
export const GET_MASTER_COMPANY_DATA = "GET_MASTER_COMPANY_DATA";
export const DELETE_MASTER_COMPANY = "DELETE_MASTER_COMPANY";
export const FETCH_MASTER_COMPANIES = "FETCH_MASTER_COMPANIES";

export const CREATE_COMPANY_INVOICE = "CREATE_COMPANY_INVOICE";
export const FETCH_COMPANY_INVOICE_DETAILS = "FETCH_COMPANY_INVOICE_DETAILS";
export const FETCH_COMPANY_INVOICE_APP_DETAIL =
  "FETCH_COMPANY_INVOICE_APP_DETAIL";
export const REPRINT_COMPANY_INVOICE = "REPRINT_COMPANY_INVOICE";

export const FETCH_TRAINING_LINK = "FETCH_TRAINING_LINK";
export const SUBMIT_TRAINING_LINK = "SUBMIT_TRAINING_LINK";

// sp appointment and enrollment report
export const FETCH_SP_SERVICE_REPORT = "FETCH_SP_SERVICE_REPORT";
export const FETCH_SP_SERVICE_APP_REPORT = "FETCH_SP_SERVICE_APP_REPORT";

export const FETCH_B_LOCATION_AND_SERVICE_PROVIDER_USER =
  "FETCH_B_LOCATION_AND_SERVICE_PROVIDER_USER";

export const FETCH_DEFAULT_ORI_RATE_LISTS = "FETCH_DEFAULT_ORI_RATE_LISTS";
export const UPDATE_DEFAULT_ORI_RATE_LISTS = "UPDATE_DEFAULT_ORI_RATE_LISTS";
export const REMOVE_ARCHIVE = "REMOVE_ARCHIVE";

export const DOWNLOAD_CLIENT_ARCHIVAL_APPOINTMENT =
  "DOWNLOAD_CLIENT_ARCHIVAL_APPOINTMENT";


export const FETCH_SERVICECODES_BY_SP="FETCH_SERVICECODES_BY_SP";
export const UPDATE_SERVICECODES_BY_SP="UPDATE_SERVICECODES_BY_SP";