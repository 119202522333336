//auth module
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_ERROR = "setError";
export const SET_USER = "setUser";
export const SET_IMPERSONATOR = "setImpersonator";
export const SET_USER_PERMISSIONS = "setUserPermissions";
export const SET_SERVICE_NAME = "SET_SERVICE_NAME";

//htmclass module
export const SET_CLASSNAME_BY_POSITION = "setClassNameByPosition";

//breadcrumb
export const SET_BREADCRUMB = "setBreadcrumb";
export const ADD_BREADCRUMB = "addBreadcrumb";

//frontend
//services
export const SET_F_SERVICES = "setFServices";
export const SET_F_SERVICE_SLUG = "setFServiceSlug";
export const SET_SCHEDULE_TIMES = "setScheduleTimes";

export const SET_CLIENT_ID = "setClientId";

export const SET_APPOINTMENT_DATE = "setAppointmentDate";
export const SET_APPOINTMENT_TIME = "setAppointmentTime";
export const SET_APPLICATION_INFO_ID = "SET_APPLICATION_INFO_ID";
//clients
export const SET_F_CLIENTS = "setFClients";
export const SET_F_CLIENT_NAME = "setFClientName";
export const SET_F_CLIENT_RATE = "setClientRate";
export const SET_F_ALERT_MSG = "setFAlertMsg";
export const SET_CLIENT_SLUG = "SET_CLIENT_SLUG";

//rate
export const SET_RATE_TOTAL_AMOUNT = "setRateTotalAmount";
export const SET_ADD_RATE_QTY = "setAddRateQty";

// /Abbreviation
export const SET_CHECK_F_ABBREVIATION_CODE = "setCheckFAbbreviationCode";
export const UPDATE_ABBREVIATION_CODE = "updateAbbreviationCode";

//appointments
export const SET_C_APPOINTMENT_BASE_PLAN = "setCAppoinmentBasePlan";
export const SET_APPT_TOKEN = "SET_APPT_TOKEN";

export const SET_SCHEDULE_TEMP_DATA = "setSecheduleTemData";

//master
export const SET_SMS_STATUS = "SET_SMS_STATUS";
export const SET_COUNTRY_PHONE_CODE = "SET_COUNTRY_PHONE_CODE";

//payment details
export const SET_PAYMENT_CARD_NUMBER = "SET_PAYMENT_CARD_NUMBER";
export const SET_PAYMENT_CARD_HOLDER_NAME = "SET_PAYMENT_CARD_HOLDER_NAME";
export const SET_PAYMENT_CARD_ADDRESS = "SET_PAYMENT_CARD_ADDRESS";
export const SET_PAYMENT_CARD_CITY = "SET_PAYMENT_CARD_CITY";
export const CARD_STATE = "CARD_STATE";
export const CARD_COUNTRY = "CARD_COUNTRY";

export const SET_PAYMENT_CARD_EXPIRY_DATE = "SET_PAYMENT_CARD_EXPIRY_DATE";
export const SET_PAYMENT_CARD_CVV_NUMBER = "SET_PAYMENT_CARD_CVV_NUMBER";
export const SET_PAYMENT_CARD_TYPE = "SET_PAYMENT_CARD_TYPE";
export const SET_PAYMENT_CARD_PROPERTY = "SET_PAYMENT_CARD_PROPERTY";
// export const SET_SMS_STATUS = "SET_SMS_STATUS";

export const SET_CITIES = "setCities";
export const SET_STATES = "setStates";
export const SET_COUNTRIES = "setCountries";
export const SET_RACES = "setRaces";
export const SET_EYECOLORS = "setEyeColors";
export const SET_HAIRCOLORS = "setHairColors";
export const SET_PlACE_OF_BIRTH = "setPlaceOfBirth";

//update master contract information

export const UPDATE_CITY = "updateCity";
export const UPDATE_ORI = "updateOri";
export const UPDATE_STATE = "updateState";
export const UPDATE_FIRSTNAME = "updateFirstName";
export const UPDATE_MIDDLENAME = "updateMiddleName";
export const UPDATE_LASTNAME = "updateLastName";
export const UPDATE_EMAIL = "updateEmail";
export const UPDATE_CONFIRMEMAIL = "updateConfirmEmail";
export const UPDATE_PHONENO = "updatePhoneNo";
export const UPDATE_ADDRESSLINE1 = "updateAddressLine1";
export const UPDATE_ADDRESSLINE2 = "updateAddressLine2";
export const UPDATE_ZIPCODE = "updateZipCode";
export const UPDATE_REASONFORFINGERPRINT = "updateReasonForFingerPrint";

//personal information
export const UPDATE_SOCIAL_SECURITY = "updateSocialSecurityNo";
export const UPDATE_DATEOFBIRTH = "updateDateOfBirth";
export const UPDATE_PLACE_OF_BIRTH = "updatePlaceOfBirth";
export const UPDATE_COUNTRY = "updateCountry";
export const UPDATE_GENDER = "updateGender";
export const UPDATE_RACE = "updateRace";
export const UPDATE_EYECOLOR = "updateEyeColor";
export const UPDATE_HAIRCOLOR = "updateHairColor";
export const UPDATE_HEIGHT = "updateHeight";
export const UPDATE_WEIGHT = "updateWeight";
export const UPDATE_DATEOFBIRTH_MONTH = "UPDATE_DATEOFBIRTH_MONTH";
export const UPDATE_DATEOFBIRTH_DAY = "UPDATE_DATEOFBIRTH_DAY";
export const UPDATE_DATEOFBIRTH_YEAR = "UPDATE_DATEOFBIRTH_YEAR";

//paymentmethod
export const UPDATE_PAYMENT_TYPE = "UPDATE_PAYMENT_TYPE";

export const SET_PAYMENT_CARD_NUMBER_POSTAL = "SET_PAYMENT_CARD_NUMBER_POSTAL";
export const SET_PAYMENT_CARD_NUMBER_PHONE = "SET_PAYMENT_CARD_NUMBER_PHONE";
export const SET_PAYMENT_CARD_NUMBER_PHONE_CODE =
  "SET_PAYMENT_CARD_NUMBER_PHONE_CODE";

export const SET_SERVICE_ID = "SET_SERVICE_ID";

export const SET_CLIENT_APPOINTMENT = "SET_CLIENT_APPOINTMENT";

//spinner
export const SET_LOADING_SPINNER = "SET_LOADING_SPINNER";

export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";

export const SET_APPOINTMENT_ID = "SET_APPOINTMENT_ID";

export const SET_CLIENTS = "SET_CLIENTS";
export const SET_CLIENTS_SP = "SET_CLIENTS_SP";
export const SET_SERVICES = "SET_SERVICES";
export const SET_SERVICE_CODES = "SET_SERVICE_CODES";
export const SET_SERVICE_TYPE = "SET_SERVICE_TYPE";
export const SET_CITIZEN_TITLE = "SET_CITIZEN_TITLE";
export const SET_SELECTED_TIME = "SET_SELECTED_TIME";
export const SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT =
  "SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT";

export const SET_SETTING_NOTE = "SET_SETTING_NOTE";

export const SET_TIME_ZONE = "SET_TIME_ZONE";

//company

export const SET_COMPANIES = "SET_C_COMPANIES";

//Services

export const SET_B_SERVICES = "SET_B_SERVICES";

//User
export const SET_B_USERS = "SET_B_USERS";

//locations
export const SET_B_LOCATIONS = "SET_B_LOCATIONS";
export const SET_B_LOCATION_RATE_LISTS = "SET_B_LOCATIONRATELISTS";

//sp
export const GET_SERVICE_PROVIDER_DATA = "GET_SERVICE_PROVIDER_DATA";

// log
export const SET_PAYMENT_LOG = "SET_PAYMENT_LOG";
// reschedule

export const SET_APPOINTMENT_FOR_RESCHEDULE = "SET_APPOINTMENT_FOR_RESCHEDULE";

// SERVICER PROVIDER
export const SET_SERVICE_PROVIDER_LIST = "SET_SERVICE_PROVIDER_LIST";
export const SET_SERVICE_PROVIDER = "SET_SERVICE_PROVIDER";

// HOLIDAYS
export const SET_HOLIDAYS = "SET_HOLIDAYS";
export const SET_BOOK_NOW_FROM_SP = "SET_BOOK_NOW_FROM_SP";

//new appointment no
export const SET_NEW_APP_NO = "SET_NEW_APP_NO";

// DASHBOARD
export const SET_DASHBOARD_CARD_DATA = "SET_DASHBOARD_CARD_DATA";
export const SET_SEARCH = "SET_SEARCH";
export const SET_APPOINTMENT_LOGS = "SET_APPOINTMENT_LOGS";

export const SET_QTY = "SET_QTY";
export const SET_MULTI_RECIPIENT = "SET_MULTI_RECIPIENT";
export const SET_IS_DRUG_TESTING = "SET_IS_DRUG_TESTING";
export const SET_APPICANTS = "SET_APPICANTS";
export const SET_REFRESH = "SET_REFRESH";
export const CLEAR_INTERVAL = "CLEAR_INTERVAL";
export const SET_INTERVAL = "SET_INTERVAL";
export const SETGROUP = "SETGROUP";
export const SET_LOCATION_ID = "SET_LOCATION_ID";
export const APPLICANTDETAIL = "APPLICANTDETAIL";
export const ISHEARTSPAYMENT = "ISHEARTSPAYMENT";
export const RECAPTCHATOKEN = "RECAPTCHATOKEN";
export const SETAPIKEY = "SETAPIKEY";
export const SET_ERROR_STATUS = "SET_ERROR_STATUS";
export const SET_IS_SERVICE_BY_MAIL = "SET_IS_SERVICE_BY_MAIL";
export const SET_SHOW_CAPTURE_OPTION = "SET_SHOW_CAPTURE_OPTION";
export const SET_ENABLE_ORI_RATE = "SET_ENABLE_ORI_RATE";
// ENROLLMENT
export const SET_ENROLLMENT_LOGS = "SET_ENROLLMENT_LOGS";
export const SET_ENROLLMENT = "SET_ENROLLMENT";
export const SET_ROLE = "SET_ROLE";
export const SET_ROLE_PERMISSIONS = "SET_ROLE_PERMISSIONS";
export const SET_ARCHIVAL = "SET_ARCHIVAL";
export const SET_B_ARCHIVAL_RATE_LISTS = "SET_B_ARCHIVAL_RATE_LISTS";
export const SET_B_ARCHIVAL = "SET_B_ARCHIVAL";

export const SET_F_ARCHIVAL_CLIENT_RATE = "SET_F_ARCHIVAL_CLIENT_RATE";

export const SET_ARCHIVAL_PERIOD_ID = "SET_ARCHIVAL_PERIOD_ID";
export const SET_ARCHIVAL_TOTAL_RATE_AMOUNT = "SET_ARCHIVAL_TOTAL_RATE_AMOUNT";

// client jobs
export const SET_CLIENT_JOB_LIST = "SET_CLIENT_JOB_LIST";
export const SET_CLIENT_JOB_DETAILS = "SET_CLIENT_JOB_DETAILS";

//booking amount

export const SET_TOTAL_SERVICE_AMOUNT = "SET_TOTAL_SERVICE_AMOUNT";
export const SET_ADDITIONAL_CARD_TOTAL_AMOUNT =
  "SET_ADDITIONAL_CARD_TOTAL_AMOUNT";

//mobile location

export const SET_MOBILE_LOCATION_JOB = "SET_MOBILE_LOCATION_JOB";
export const MOBILE_APPOINTMENT_APPLICATS = "MOBILE_APPOINTMENT_APPLICATS";

export const SET_SERVICEORI = "SET_SERVICEORI";
export const SET_SERVICE_TYPE_CODE = "SET_SERVICE_TYPE_CODE";
export const SET_SERVICE_ORI_LIST = "SET_SERVICE_ORI_LIST";

export const SET_CHECK_F_SERVICE_ORI_CODE = "SET_CHECK_F_SERVICE_ORI_CODE";
export const UPDATE_SERVICE_ORI_CODE = "UPDATE_SERVICE_ORI_CODE";
export const SETAPIKEYVERSIONLIST = "SETAPIKEYVERSIONLIST";

export const SET_PROMOCODES = "SET_PROMOCODES";

export const UPDATE_PROMO_CODE = "UPDATE_PROMO_CODE";
export const SET_CHECK_F_PROMO_CODE = "SET_CHECK_F_PROMO_CODE";
export const SET_PROMOCODE_DISCOUNT_AMOUNT = "SET_PROMOCODE_DISCOUNT_AMOUNT";
export const SET_PROMOCODE_DISCOUNT_PERCENTAGE =
  "SET_PROMOCODE_DISCOUNT_PERCENTAGE";

export const SET_WHITELIST_DETAILS = "SET_WHITELIST_DETAILS";
export const SET_SERVICECODES = "SET_SERVICECODES";
//common
export const SET_WIZARD_FORM_BUTTON_DISABLE = "SET_WIZARD_FORM_BUTTON_DISABLE";
export const SET_OTP_FOR_SMS_VERIFY = "SET_OTP_FOR_SMS_VERIFY";
export const SET_OTP_FOR_EMAIL_VERIFY = "SET_OTP_FOR_EMAIL_VERIFY";
export const SET_ARCHIVALS = "SET_ARCHIVALS";

export const SET_APIKEYS = "SET_APIKEYS";
export const STORE_ARCHIVAL_DATE = "STORE_ARCHIVAL_DATE";
export const SET_ARCHIVAL_RENEW_DATE = "SET_ARCHIVAL_RENEW_DATE";
export const SET_APP_TYPE = "SET_APP_TYPE";
export const SET_ARCHIVAL_CUSTOM_DATE = "SET_ARCHIVAL_CUSTOM_DATE";
export const SET_ARCHIVAL_CUSTOM_PRICE = "SET_ARCHIVAL_CUSTOM_PRICE";
export const SET_ARCHIVAL_CUSTOM = "SET_ARCHIVAL_CUSTOM";

export const SET_MASTER_COMPANIES = "SET_MASTER_COMPANIES";
export const SET_COMPANY_INVOICE_APPOINTMENTS =
  "SET_COMPANY_INVOICE_APPOINTMENTS";
