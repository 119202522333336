// export const API_URL = "http://10.2.3.129:8000/api/v1";
// export const API_URL = "http://10.2.3.86:8000/api/v1";
// export const API_URL = "http://10.2.3.101:8000/api/v1";
// export const API_URL = "http://10.5.252.48:8000/api/v1";
// export const API_URL = "http://192.168.1.112:8000/api/v1";
// export const API_URL = "http://10.2.3.122:8000/api/v1";
// export const API_URL = "http://10.2.3.110:8000/api/v1";
// export const API_URL = "http://10.2.3.197:8000/api/v1";
// export const API_URL = "http://10.2.3.143:8000/api/v1";
// export const API_URL = "http://10.2.3.162:8000/api/v1";
// export const API_URL = "https://uatscanapi.mavorion.com/api/v1";
// export const API_URL = "https://scanapi.mavorion.com/api/v1";

// export const API_URL = "https://uatscanapi.totalbiometrics.net/api/v1";
export const API_URL = "https://scanapi.totalbiometrics.net/api/v1";

export default API_URL;
